import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { breadcrumbs } from "../../helpers/schema";

import Page from "../../components/Page";
import Hero from "../../components/Hero";
import Tile from "../../components/Tile";
import Card from "../../components/Card";
import Kranicon from "../../components/Kranicon";
import Container from "../../components/Container";
import OnOfficeFrame from "../../components/OnOfficeFrame";
import PageBlock from "../../components/PageBlock";

const Angebote = ({ data }) => (
  <Page
    header={
      <Hero
        fluid={data.propertiesImage.fluid}
        icon={<Kranicon icon="suche" />}
        title="Immobilien in Rudolstadt und Umgebung"
        intro="Entdecken Sie unsere aktuellen Angebote aus Rudolstadt und Umgebung"
      />
    }
  >
    <Helmet
      title="Immobilien in Rudolstadt finden"
      meta={[
        {
          name: "description",
          content:
            "Finden Sie die perfekte Immobilie in Rudolstadt. Entdecken Sie alle unsere Angebote.",
        },
      ]}
      script={[
        breadcrumbs({
          crumbs: [
            {
              name: "Suchen",
              link: "/angebote/",
              position: 1,
            },
          ],
          siteUrl: data.site.siteMetadata.siteUrl,
          siteTitle: data.site.siteMetadata.title,
        }),
      ]}
    />
    <PageBlock>
      <Tile>
        <Card>
          <Container spaced>
            <h2>Aktuelle Angebote</h2>
            <p>Hier finden Sie eine großartige Auswahl in Immobilien in Rudolstadt und Umgebung. Zögern Sie bei Interesse oder Fragen rund um unsere Immobilien in Rudolstadt nicht uns zu kontaktieren</p>
            <OnOfficeFrame />
          </Container>
        </Card>
      </Tile>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    propertiesImage: imageSharp(
      fluid: { originalName: { regex: "/properties/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export default Angebote;
