import React from "react";
import IframeResizer from "iframe-resizer-react";

const OnOfficeFrame = () => {
  return (
    <IframeResizer
      id="onoffice"
      width="100%"
      height="100%"
      frameBorder="none"
      src="https://smartsite2.myonoffice.de/kunden/baeumer/11/"
    />
  );
};

export default OnOfficeFrame;
